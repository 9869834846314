import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './services/user/auth.guard';
import { KioskoGuard } from './services/appGuard/kiosko.guard';
import { HomeGuard } from './services/homeGuard/home.guard';

const routes: Routes = [
  {
    path: '', loadChildren: () => import('./pages/walkthrough/walkthrough.module').then(m => m.WalkthroughPageModule), canActivate: [KioskoGuard, AuthGuard],
  },
  { path: 'home', loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule), canActivate:[HomeGuard]},
  { path: 'login', loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule), canActivate: [AuthGuard] },
//   { path: 'register', loadChildren: () => import('./pages/register/register.module').then(m => m.RegisterPageModule), canActivate: [AuthGuard] },
  { path: 'edit-profile', loadChildren: () => import('./pages/edit-profile/edit-profile.module').then(m => m.EditProfilePageModule) , canActivate: [HomeGuard]},
  { path: 'booking-list', loadChildren: () => import('./pages/booking-list/booking-list.module').then(m => m.BookingListPageModule) , canActivate: [HomeGuard]},
  { path: 'favorites', loadChildren: () => import('./pages/favorites/favorites.module').then(m => m.FavoritesPageModule) , canActivate: [HomeGuard]},
  { path: 'about', loadChildren: () => import('./pages/about/about.module').then(m => m.AboutPageModule) , canActivate: [HomeGuard]},
  { path: 'support', loadChildren: () => import('./pages/support/support.module').then(m => m.SupportPageModule) , canActivate: [HomeGuard]},
  { path: 'rentcar', loadChildren: () => import('./pages/rentcar/rentcar.module').then(m => m.RentcarPageModule) , canActivate: [HomeGuard]},
  { path: 'UpgrateServicio', loadChildren: () => import('./pages/modal/upgrate-service/upgrate-service.module').then(m => m.UpgrateServicePageModule) , canActivate: [HomeGuard]},
  { path: 'activities', loadChildren: () => import('./pages/activities/activities.module').then(m => m.ActivitiesPageModule) , canActivate: [HomeGuard]},
  { path: 'factura', loadChildren: () => import('./pages/factura/factura.module').then(m => m.FacturaModule), canActivate: [HomeGuard]},
  { path: 'wifi', loadChildren: () => import('./pages/wifi-password/wifi-password.module').then(m => m.WifiPasswordPageModule), canActivate: [HomeGuard]},
  { path: 'NewUpdate', loadChildren: () => import('./pages/new-update/new-update.module').then(m => m.NewUpdatePageModule) , canActivate: [HomeGuard]},
  { path: 'speedtest', loadChildren: () => import('./pages/speedtest/speedtest.module').then(m => m.SpeedtestPageModule) , canActivate: [HomeGuard]},
  { path: 'iframe-renderer', loadChildren: () => import('./pages/modal/iframe-renderer/iframe-renderer.module').then(m => m.IframeRendererPageModule) , canActivate: [HomeGuard]},
  {
    path: 'add-cedula',
    loadChildren: () => import('./pages/add-cedula/add-cedula.module').then(m => m.AddCedulaPageModule),
    canActivate: [HomeGuard]
  },
  {
    path: 'services',
    loadChildren: () => import('./pages/modal/show-services/show-services.module').then(m => m.ShowServicesPageModule),
    canActivate: [HomeGuard]
  },
  {
    path: 'edit-cedula',
    loadChildren: () => import('./pages/edit-cedula/edit-cedula.module').then(m => m.EditCedulaPageModule),
    canActivate: [HomeGuard]
  },
  {
    path: 'contrato',
    loadChildren: () => import('./pages/contrato/contrato.module').then(m => m.ContratoPageModule),
    canActivate: [HomeGuard]
  },
  {
    path: 'ticket',
    loadChildren: () => import('./pages/ticket/ticket.module').then(m => m.TicketPageModule),
    canActivate: [HomeGuard]
  },
  {
    path: 'chat',
    loadChildren: () => import('./pages/chat/chat.module').then(m => m.ChatPageModule),
    canActivate: [HomeGuard]
  },
  {
    path: 'notification',
    loadChildren: () => import('./pages/notification/notification.module').then(m => m.NotificationPageModule),
    canActivate: [HomeGuard]
  },
  {
    path: 'view-notify',
    loadChildren: () => import('./pages/view-notify/view-notify.module').then( m => m.ViewNotifyPageModule),
    canActivate: [HomeGuard]
  },
  {
    path: 'reporte-averia-modal',
    loadChildren: () => import('./pages/modal/reporte-averia-modal/reporte-averia-modal.module').then(m => m.ReporteAveriaModalPageModule),
    canActivate: [HomeGuard]
  },
  {
    path: 'referidos',
    loadChildren: () => import('./pages/referidos/referidos.module').then(m => m.ReferidosPageModule),
    canActivate: [HomeGuard]
  },
  {
    path: 'mi-equipo',
    loadChildren: () => import('./pages/mi-equipo/mi-equipo.module').then(m => m.MiEquipoPageModule),
    canActivate: [HomeGuard]
  },
  {
    path: 'list-cuenta',
    loadChildren: () => import('./pages/modal/list-cuenta/list-cuenta.module').then(m => m.ListCuentaPageModule),
    canActivate: [HomeGuard]
  },
  {
    path: 'propaganda',
    loadChildren: () => import('./pages/modal/propaganda/propaganda.module').then(m => m.PropagandaPageModule),
    canActivate: [HomeGuard]
  },
  {
    path: 'manuales',
    loadChildren: () => import('./pages/manuales/manuales.module').then(m => m.ManualesPageModule),
    canActivate: [HomeGuard]
  },
  {
    path: 'pin-master',
    loadChildren: () => import('./pages/modal/pin-master/pin-master.module').then(m => m.PinMasterPageModule),
    canActivate: [HomeGuard]
  },
  {
    path: 'new-login',
    loadChildren: () => import('./pages/new-login/new-login.module').then(m => m.NewLoginPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'fibex-remote-control',
    loadChildren: () => import('./pages/ctrlremote/ctrlremote.module').then(m => m.CtrlremoteModule),
    canActivate: [HomeGuard]
  },
  {
    path: 'planes',
    loadChildren: () => import('./pages/planes/planes.module').then(m => m.PlanesPageModule),
    canActivate: [HomeGuard]
  },
  {
    path: 'club-fibex',
    loadChildren: () => import('./pages/fibex-club/club-tabs/club-tabs.module').then(m => m.ClubTabsModule),
    canActivate:[HomeGuard]
  },
  {
    path: 'security',
    loadChildren: () => import('./pages/security/security.module').then(m => m.SecurityPageModule),
    canActivate: [HomeGuard]
  },
  {
    path: 'push-message',
    loadChildren: () => import('./pages/push-message/push-message.module').then( m => m.PushMessagePageModule),
    canActivate: [HomeGuard]
  },
  {
    path: 'speedtest',
    loadChildren: () => import('./pages/speedtest/speedtest.module').then( m => m.SpeedtestPageModule),
    canActivate: [HomeGuard]
  },
  {
    path: 'iframe-renderer',
    loadChildren: () => import('./pages/modal/iframe-renderer/iframe-renderer.module').then( m => m.IframeRendererPageModule),
    canActivate: [HomeGuard]
  },
  {
    path: 'recommend-and-win',
    loadChildren: () => import('./pages/recommend-and-win/recommend-and-win.module').then( m => m.RecommendAndWinPageModule),
    canActivate: [HomeGuard]
  },
  {
    path: 'club-fibex/club-iframe',
    pathMatch: 'full',
    loadChildren: () => import('./pages/fibex-club/club-iframe/club-iframe.module').then( m => m.ClubIframePageModule),
    canActivate: [HomeGuard]
  },
  {
    path: 'support-bot',
    loadChildren: () => import('./support-bot/support-bot.module').then( m => m.SupportBotPageModule),
    canActivate: [HomeGuard]
  },
  {
    path: '**', redirectTo: 'home'
  }





];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
